<template>
  <v-container class="px-0 pb-6">
    <v-form>
      <v-row class="my-0">
        <v-col cols="12" class="py-0">
          <v-row>
            <v-col cols="12">
              <h2 class="py-8" v-text="$t('club.clubEnrollmentForm.clubData.title')" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h3 v-text="$t('project.sections.generalInformation')" />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-1 px-3" cols="12" sm="6">
              <div class="mb-3 d-flex">
                <h4 class="mr-3" v-text="$t('common.logo')" />
                <global-tooltip
                  :text="$t('common.tooltipImageSizes', {
                    proportion: '1:1',
                    width: '300px',
                    height: '300px'
                  })"
                />
              </div>
              <image-field
                v-model="form.logo"
                :error-messages="getFieldErrors('logo')"
                :width="300" :height="300"
                suffix="_logo"
                @input="$v.form.logo.$touch()"
              />
            </v-col>
          </v-row>
          <v-row :class="[
            'mt-9',
            disabledFieldIds.includes('isFederated') ? 'mb-9' : null
          ]"
          >
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="form.name" :label="$t('club.clubEnrollmentForm.clubData.name')"
                :error-messages="getFieldErrors('name')" outlined dense
              />
            </v-col>
            <v-col v-if="!disabledFieldIds.includes('legalName')" cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="form.legalName" :label="`${$t('club.editClub.socialDenomination')} ${$t('common.optional')}`"
                outlined dense
              />
            </v-col>
            <v-col v-if="!disabledFieldIds.includes('legalEntityId')" cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="form.legalEntityId" :label="$t('club.editClub.cif/nif')"
                :error-messages="getFieldErrors('legalEntityId')" outlined dense
              />
            </v-col>
            <v-col v-if="!disabledFieldIds.includes('website')" cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="form.website" :label="`${$t('club.editClub.webPage')}  ${$t('common.optional')}`"
                :error-messages="getFieldErrors('website')"
                outlined dense
              >
                <template #append>
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-icon color="primary" v-on="on" @click="copyToClipboard(form.website)">
                        content_copy
                      </v-icon>
                    </template>
                    {{ $t('club.editClub.copyLink') }}
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="!disabledFieldIds.includes('isFederated')" class="mb-9">
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-switch v-model="form.isFederated" :label="$t('club.editClub.isFederated')" />
            </v-col>
            <v-col v-if="form.isFederated" cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="form.federationPlace" :label="$t('club.editClub.whereIsFederated')"
                :error-messages="getFieldErrors('federationPlace')"
                outlined dense
              />
            </v-col>
          </v-row>
          <v-row v-if="!disabledFieldIds.includes('address')" class="mt-9">
            <v-col cols="12">
              <h2 v-text="$t('club.editClub.clubAddress')" />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="form.address.line1" :label="$t('common.addressLine1')"
                :messages="$t('common.street')" :error-messages="getFieldErrors('address.line1')" outlined dense
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="form.address.line2" :label="$t('common.addressLine2')"
                :messages="$t('common.additionalInformationStreet')" :error-messages="getFieldErrors('address.line2')"
                outlined dense
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="form.address.zip" :label="$t('common.postalCode')"
                :error-messages="getFieldErrors('address.zip')" outlined dense
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="form.address.city" :label="$t('common.city')"
                :error-messages="getFieldErrors('address.city')" outlined dense
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-text-field
                v-model="form.address.region" :label="$t('common.stateProvinceRegion')"
                :error-messages="getFieldErrors('address.region')" outlined dense
              />
            </v-col>
            <v-col cols="12" sm="6" class="py-1 px-3">
              <v-select
                v-model="form.address.country" :items="countryItems"
                :label="$t('user.fields.country')" :error-messages="getFieldErrors('address.country')"
                outlined dense hide-details="auto" append-icon="mdi-chevron-down"
              />
            </v-col>
          </v-row>
          <v-row v-if="!disabledFieldIds.includes('kitsByIndex')" class="mt-9">
            <v-col cols="12">
              <h2 v-text="$t('club.editClub.equipement')" />
            </v-col>
            <v-col v-for="(index) in [0, 1]" :key="index" cols="12" sm="6" class="py-1 px-3">
              <v-row>
                <v-col cols="12">
                  <h3 v-text="`${index + 1}.º ${$t('club.editClub.equipement')}`" />
                </v-col>
                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-select
                        v-model="form.kitsByIndex[index].jersey.type" :items="jerseyStyles"
                        :error-messages="getFieldErrors(`kitsByIndex.${index}.jersey.type`)"
                        :label="$t('club.editClub.tShirtPrint')" outlined dense append-icon="mdi-chevron-down"
                        @change="$event => { if ($event === 'solid') form.kitsByIndex[index].jersey.color2 = null }"
                      />
                    </v-col>
                    <v-col cols="6">
                      <span>{{ $t('club.editClub.jerseyColor') }}</span>
                    </v-col>
                    <v-col cols="6">
                      <div class="d-flex">
                        <color-field
                          v-model="form.kitsByIndex[index].jersey.color1" class="mr-4"
                          :error-messages="getFieldErrors(`kitsByIndex.${index}.jersey.color1`)"
                        />
                        <color-field
                          v-if="form.kitsByIndex[index].jersey.type && form.kitsByIndex[index].jersey.type !== 'solid'"
                          v-model="form.kitsByIndex[index].jersey.color2"
                          :error-messages="getFieldErrors(`kitsByIndex.${index}.jersey.color2`)"
                        />
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <span>{{ $t('club.editClub.pantsColor') }}</span>
                    </v-col>
                    <v-col cols="6">
                      <color-field
                        v-model="form.kitsByIndex[index].pants.color1" class="mr-2"
                        :error-messages="getFieldErrors(`kitsByIndex.${index}.pants.color1`)"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="!disabledFieldIds.includes('requiresAccomodation')" class="mt-9">
            <v-col cols="12">
              <h2 v-text="$t('club.accomodation.title')" />
            </v-col>
            <v-col cols="12" class="py-1 px-3">
              <v-switch v-model="form.requiresAccomodation" :label="$t('club.clubEnrollmentForm.clubData.requiresAccomodation')" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { required, requiredIf, url } from 'vuelidate/lib/validators'
import preventReload from '@/utils/mixins/preventReload'
import formUtils from '@/utils/mixins/formUtils'
import i18nCountries from '@/utils/mixins/i18nCountries'
import clubJerseyStylesEnum from '@/enums/clubJerseyStylesEnum'

export default {
  name: 'ClubData',
  components: {
    ImageField: () => import('@/components/formFields/ImageField'),
    GlobalTooltip: () => import('@/components/GlobalTooltip'),
    ColorField: () => import('@/components/formFields/ColorField'),
  },
  mixins: [formUtils, i18nCountries, preventReload],
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
    globalForm: { type: Object, required: true },
  },
  data() {
    return {
      orlegiProjectid: '5zFckTs99PpoWAGlNphT',
      jerseyStyles: clubJerseyStylesEnum,
      form: {
        logo: this.globalForm.logo ?? null,
        name: this.globalForm.name ?? null,
        legalName: this.globalForm.legalName ?? null,
        legalEntityId: this.globalForm.legalEntityId ?? null,
        website: this.globalForm.website ?? null,
        isFederated: this.globalForm.isFederated ?? false,
        federationPlace: this.globalForm.federationPlace ?? null,
        address: {
          line1: this.globalForm.address?.line1 ?? null,
          line2: this.globalForm.address?.line2 ?? null,
          zip: this.globalForm.address?.zip ?? null,
          city: this.globalForm.address?.city ?? null,
          region: this.globalForm.address?.region ?? null,
          country: this.globalForm.address?.country ?? null,
        },
        kitsByIndex: {
          0: {
            jersey: {
              color1: this.globalForm.kitsByIndex?.[0]?.jersey?.color1 ?? null,
              color2: this.globalForm.kitsByIndex?.[0]?.jersey?.color2 ?? null,
              type: this.globalForm.kitsByIndex?.[0]?.jersey?.type ?? null,
            },
            pants: {
              color1: this.globalForm.kitsByIndex?.[0]?.pants?.color1 ?? null,
            },
          },
          1: {
            jersey: {
              color1: this.globalForm.kitsByIndex?.[1]?.jersey?.color1 ?? null,
              color2: this.globalForm.kitsByIndex?.[1]?.jersey?.color2 ?? null,
              type: this.globalForm.kitsByIndex?.[1]?.jersey?.type ?? null,
            },
            pants: {
              color1: this.globalForm.kitsByIndex?.[1]?.pants?.color1 ?? null,
            },
          },
        },
        requiresAccomodation: this.globalForm.requiresAccomodation ?? false,
      },
    }
  },
  validations() {
    return {
      form: {
        logo: { required },
        name: { required },
        ...(!this.disabledFieldIds.includes('website') && { website: { url } }),
        ...(!this.disabledFieldIds.includes('legalEntityId') && { legalEntityId: { required } }),
        ...(!this.disabledFieldIds.includes('isFederated') && { federationPlace: { required: requiredIf(() => this.form.isFederated) } }),
        ...(!this.disabledFieldIds.includes('address') && {
          address: { line1: { required }, line2: { required }, zip: { required }, city: { required }, region: { required }, country: { required } },
        }),
        ...(!this.disabledFieldIds.includes('kitsByIndex') && {
          kitsByIndex: {
            0: {
              jersey: {
                color1: { required },
                color2: { required: requiredIf(() => this.form.kitsByIndex[0].jersey.type !== 'solid') },
                type: { required },
              },
              pants: { color1: { required } },
            },
            1: {
              jersey: {
                color1: { required },
                color2: { required: requiredIf(() => this.form.kitsByIndex[1].jersey.type !== 'solid') },
                type: { required },
              },
              pants: { color1: { required } },
            },
          },
        }),
      },
    }
  },
  computed: {
    ...mapGetters({ project: 'project/data' }),
    ...mapGetters('clubEnrollmentForm', ['template']),
    disabledFieldIds: ({ template }) => template?.disabledFieldIds ?? [],
  },
  created: () => window.scrollTo(0, 0),
}
</script>
